@import "../base.css";

.classic_theme_container {
  height: 250px;
  overflow-y: scroll;
}

.classic_theme_container .classic_time {
  cursor: pointer;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #f3f3f3;
  background-color: #fff;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -ms-transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.classic_theme_container .classic_time .meridiem {
  font-size: 0.8em;
  opacity: 0.7;
  padding-left: 5px;
}

.classic_theme_container .classic_time.dark.active,
.classic_theme_container .classic_time.dark:hover {
  background-color: #4a4a4a;
  color: #fff;
}

.classic_theme_container .classic_time.light.active,
.classic_theme_container .classic_time.light:hover {
  background-color: #3498db;
  color: #fff;
}

/* dark theme */
.dark .classic_theme_container {
  background-color: #4a4a4a;
}

.dark .classic_theme_container .classic_time {
  border-bottom: 1px solid #5d5d5d;
  background-color: #4a4a4a;
  color: #fff;
}

.dark .classic_theme_container .classic_time.active,
.dark .classic_theme_container .classic_time:hover {
  background-color: #343434;
}
