.time_picker_container {
  position: relative;
}

.time_picker_preview {
  height: 50px;
}

.time_picker_preview:not(.disabled):active, .time_picker_preview:not(.disabled).active {
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.12), 0 0 8px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.12), 0 0 8px 0 rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.12), 0 0 8px 0 rgba(0, 0, 0, 0.08);
}

.time_picker_preview.disabled {
  cursor: not-allowed;
}

.preview_container {
  position: absolute;
  left: 50%;
  height: 50px;
  line-height: 50px;
  padding-left: 30px;
  transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
}

.preview_container.without_icon {
  padding-right: 30px;
}

.preview_container svg {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 12px;
  left: 0;
}

.react_times_button {
  user-select: none;
  position: relative;
  cursor: pointer;
  color: #343434;
  border-radius: 2px;
  background-color: #fff;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -ms-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, .15);
  -moz-box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, .15);
  -webkit-box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, .15);
}

.react_times_button.pressDown {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.react_times_button.pressDown .wrapper {
  transform: translateY(1px);
}

.react_times_button .wrapper {
  transform: translateY(0);
  height: 100%;
}

.modal_container {
  user-select: none;
  cursor: default;
  position: absolute;
  width: 100%;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -ms-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: #fff;
  border-radius: 2px;
  top: 100%;
  left: 0;
  box-shadow: 4px 4px 30px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 4px 4px 30px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 4px 4px 30px 0 rgba(0, 0, 0, 0.2);

  opacity: 0;
  z-index: -1;
  visibility: hidden;
  backface-visibility: hidden;
  transform: scale(0.7) translateY(20px);
  -ms-transform: scale(0.7) translateY(20px);
  -moz-transform: scale(0.7) translateY(20px);
  -o-transform: scale(0.7) translateY(20px);
  -webkit-transform: scale(0.7) translateY(20px);
}

.outside_container.active .modal_container {
  opacity: 1;
  z-index: 2;
  visibility: visible;
  transform: scale(1) translateY(20px);
  -ms-transform: scale(1) translateY(20px);
  -moz-transform: scale(1) translateY(20px);
  -o-transform: scale(1) translateY(20px);
  -webkit-transform: scale(1) translateY(20px);
}